<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="beforeSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row
          class="pt-5"
        >
          <v-col
            cols="12"
          >
            Cette fenêtre permet de modifier la position d'un dossier de publication, permettant ainsi de définir sa visibilité dans l'ensemble du logiciel.
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="publicationId"
              :rules="[validators.required]"
              :error-messages="errorMessages.title"
              :label="$t('Publication')"
              item-text="id"
              item-value="id"
              outlined
              :multiple="false"
              dense
              hide-details="auto"
              :items="publications"
              :loading="isLoadingPublications"
              :search-input.sync="search"
              clearable
            >
              <template #item="{ item }">
                <div @click="setObject(item)">
                  <span>{{ item.id }} - {{ item.author.full_name }}</span>
                </div>
                <!--                  <AvatarName :options="{avatar: item.author.avatar, label: item.author.full_name, publication: item.id }" />-->
              </template>
              <template #selection="{ item }">
                <span>{{ item.id }} - {{ item.author.full_name }}</span>
                <!--                  <AvatarName :options="{avatar: item.author.avatar, label: item.author.full_name, publication: item.id }" />-->
              </template>
            </v-autocomplete>
          </v-col>
          <v-col

            cols="12"
            md="4"
          >
            <v-text-field
              dense
              outlined
              hide-details
              readonly
              :value="currentState"
              label="Position actuelle"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-slider
              v-model="state"
              :tick-labels="statesLabels"
              :max="7"
              step="1"
              ticks="always"
              tick-size="8"
              :rules="[validators.required]"
            ></v-slider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          :loading="loading"
          :disabled="!valid"
          color="primary"
          class="me-3"
          type="submit"
        >
          {{ $t("Confirmer") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="updateDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la modification") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir modifier ?") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="updateDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            small
            color="primary"
            @click="onSubmit"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"

      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="isSnackbarBottomVisibleSuccess"
      color="success"

      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import AvatarName from '@/components/AvatarName'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import useAuthorsList from '@/views/apps/author/author-list/useAuthorsList'
import DatePicker from '@/components/DatePicker'

export default {
  components: { DatePicker, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      loadedManuscripts,
      snackBarMessage,
    } = controller()
    const {
    } = authorsController()
    const users = ref([])
    const isLoadingUsers = ref(false)
    const errorMessages = ref({ gestion: null, assistant: null })
    const isSnackbarBottomVisibleSuccess = ref(false)
    const valid = ref(false)
    const modalDate = ref(false)
    const dialogDate = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingPublications = ref(false)
    const authors = ref([])
    const publications = ref([])
    const isLoadingAuthors = ref(false)
    const updateDialog = ref(false)
    const loadingManuscript = ref(true)
    const search = ref(null)
    const publicationId = ref(null)
    const currentObject = ref(null)
    const currentState = ref('')
    const state = ref(0)
    const statesLabels = ref(['Publication', 'Envoi contrat', 'Relance contrat',
      'Collecte des éléments', 'Traitement texte + couverture', 'Supervision générale',
      'Parutions', 'Paiements'])

    const types = ref([
      { id: 'communication', label: 'Communication' },
      { id: 'publication', label: 'Parution' },
      { id: 'text', label: 'Traitement texte' },
      { id: 'cover', label: 'Traitement couverture' },
    ])
    const manuscript = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const getState = state => {
      switch (state) {
        case store.state.app.states.CREATED_STATE:
          return 'Publication'
        case store.state.app.states.SEND_STATE:
          return 'Envoi contrat'
        case store.state.app.states.RESEND_STATE:
          return 'Relance contrat'
        case store.state.app.states.SIGNED_STATE:
          return 'Collecte des éléments'
        case store.state.app.states.COLLECTED_STATE:
          return 'Traitement texte + couverture'
        case store.state.app.states.TEXT_STATE:
          return 'Traitement texte + couverture'
        case store.state.app.states.COVER_STATE:
          return 'Supervision générale'
        case store.state.app.states.SUPERVISOR_STATE:
          return 'Parutions'
        case store.state.app.states.PUBLICATION_STATE:
          return 'Paiements'
        default:
          return ''
      }
    }
    const getStateForClient = state => {
      switch (state) {
        case store.state.app.states.CREATED_STATE:
          return 0
        case store.state.app.states.SEND_STATE:
          return 1
        case store.state.app.states.RESEND_STATE:
          return 2
        case store.state.app.states.SIGNED_STATE:
          return 3
        case store.state.app.states.COLLECTED_STATE:
          return 4
        case store.state.app.states.TEXT_STATE:
          return 4
        case store.state.app.states.COVER_STATE:
          return 5
        case store.state.app.states.SUPERVISOR_STATE:
          return 6
        case store.state.app.states.PUBLICATION_STATE:
          return 7
        default:
          return 0
      }
    }

    const getStateForServer = state => {
      switch (state) {
        case 0:
          return 0
        case 1:
          return 1
        case 2:
          return 2
        case 3:
          return 3
        case 4:
          return 4
        case 5:
          return 6
        case 6:
          return 7
        case 7:
          return 8
        case 8:
          return 9
        default:
          return 0
      }
    }
    const setObject = val => {
      currentState.value = getState(val.state)
      state.value = getStateForClient(val.state)
      console.log(currentState.value)
    }
    const dialog = computed({
      get: () => props.isFormActive,
      set: value => emit('is-form-active', value),
    })

    const beforeSubmit = () => {
      updateDialog.value = true
    }
    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        axios.post('/states', {
          id: publicationId.value,
          state: getStateForServer(state.value),
        }).then(response => {
          if (response.data && response.data.error) {
            isSnackbarBottomVisible.value = true
            snackBarMessage.value = 'Dossier non trouvé'

            return
          }
          loading.value = false
          publicationId.value = null
          currentState.value = null
          state.value = 0
          isSnackbarBottomVisibleSuccess.value = true
          snackBarMessage.value = 'Success'
          form.value.reset()
          publications.value = []
          updateDialog.value = false
        }).catch(error => {
          loading.value = false
          updateDialog.value = false
          console.log('error', error)
          if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            errorMessages.value = { ...error.data.errors }
          }
        })
      } else {
        validate()
      }
    }
    const onSearch = val => {
      isLoadingPublications.value = true
      axios
        .get('/task/publications', { params: { term: val, rowsPerPage: 50 } })
        .then(response => {
          isLoadingPublications.value = false
          for (let i = 0; i < response.data.length; i++) {
            const p = response.data[i]
            const indexU = publications.value.findIndex(el => el.id === p.id)
            if (p.id && indexU < 0) {
              publications.value.push(p)
            }
          }
        }).then(() => {
          isLoadingPublications.value = false
        })
    }
    const loadUsers = val => {
      isLoadingUsers.value = true
      axios
        .get('/listWorkers', { params: { term: val, rowsPerPage: 50, type: 'states' } })
        .then(response => {
          isLoadingUsers.value = false
          for (let i = 0; i < response.data.length; i++) {
            const p = response.data[i]
            const indexU = users.value.findIndex(el => el.id === p.id)
            if (p.id && indexU < 0) {
              users.value.push(p)
            }
          }
        }).then(() => {
          isLoadingUsers.value = false
        })
    }

    loadUsers('')

    // onSearch('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.toString().trim().length < 3 || isLoadingPublications.value) return ''
      onSearch(val.toString())
    })
    watch(item, val =>

    // fetchItem()
      '',
    { deep: true })
    const fetchItem = () => {
      const man = loadedManuscripts.value.find(ele => ele.id === item.value.publication_id)
      if (man) {
        console.log('hey')
        item.value.manuscript = man
        loadingManuscript.value = false
      } else {
        if (!item.value.publication || !item.value.publication.manuscript_id) {
          loadingManuscript.value = false
          console.log('no publication')

          return false
        }
        loadingManuscript.value = true
        axios.get(`/manuscripts/${item.value.publication.manuscript_id}`).then(response => {
          loadingManuscript.value = false
          if (response.data && response.data.id) {
            item.value.manuscript = response.data
            loadedManuscripts.value.push(response.data)
          }
        }).then(() => {
          loadingManuscript.value = false
        }).catch(() => {
          loadingManuscript.value = false
        })
      }
    }
    fetchItem()
    const groupedUsers = computed({
      get: () => {
        // all users if just creating state

        const roles = ['gestionnaire', 'assistant_direct', 'assistant_indirect', 'service_publications', 'Graphisme']
        const gestionaires = users.value.filter(ele => ele.role_name === 'gestionnaire')
        const assistant_indirect = users.value.filter(ele => ele.role_name === 'assistant_indirect')
        const assistant_direct = users.value.filter(ele => ele.role_name === 'assistant_direct')
        const service_publications = users.value.filter(ele => ele.role_name === 'service_publications')
        const Graphisme = users.value.filter(ele => ele.role_name === 'Graphisme')
        const service_communication = users.value.filter(ele => ele.role_name === 'service_communication')
        const general_users = users.value.filter(ele => !roles.includes(ele.role_name))
        const values = []
        if (item.value.type === 'text' || item.value.type === 'cover') {
          if (gestionaires.length > 0) {
            values.push({ header: 'Gestionnaire' }, ...gestionaires)
          }
          if (assistant_indirect.length > 0) {
            values.push({ header: 'Assistant Indirect' }, ...assistant_indirect)
          }
          if (assistant_direct.length > 0) {
            values.push({ header: 'Assistant Direct' }, ...assistant_direct)
          }
        }
        if (item.value.type === 'cover') {
          if (Graphisme.length > 0) {
            values.push({ header: 'Graphiste' }, ...Graphisme)
          }
        }
        if (item.value.type === 'publication') {
          values.push({ header: 'Assistant Publication' }, ...service_publications)
        }
        if (item.value.type === 'communication') {
          values.push({ header: 'Assitant Communication' }, ...service_communication)
        }

        if (!item.value.id) {
          return users.value
        }

        return values
      },
    })

    return {
      groupedUsers,
      form,
      isFormAuthorActive,
      isLoadingPublications,
      isSnackbarBottomVisible,
      loadingManuscript,
      snackBarMessage,
      errorMessages,
      search,
      authors,
      types,
      publications,
      isLoadingAuthors,
      isLoadingUsers,
      updateDialog,
      onSubmit,
      beforeSubmit,
      currentState,
      setObject,
      modalDate,
      dialogDate,
      item,
      users,
      valid,
      dialog,
      loading,
      validate,
      avatarText,
      state,
      statesLabels,
      publicationId,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
